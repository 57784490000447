import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import ReturnByDateChip from 'customerPortal/components/ReturnByDateChip/ReturnByDateChip';
import Section, {SectionActionButton} from 'customerPortal/components/Section';
import {Navigation} from 'customerPortal/constants/navigationItems';
import firebase from 'firebase/app';
import React from 'react';
import clsx from 'clsx';
import {NavLink, useHistory} from 'react-router-dom';
import {ReactComponent as IconChevron} from 'shared/assets/icons/iconChevron.svg';
import {PRIVATE_ROUTES, PUBLIC_ROUTES} from 'shared/components/Route/routes';
import styles from './MobileMenu.styles';
import {MobileFeedbackContact} from 'customerPortal/components/FeedbackContact';
import ReferralLink from '../../../../components/ReferralLink/ReferralLink';

function deleteAccessToken() {
  document.cookie =
    'ello_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
interface Props extends WithStyles<typeof styles> {
  navigationItems: Navigation[];
  referralCode?: string;
  showReferralLink?: boolean;
  onClick?: () => void;
  chipContents?: string;
}

const MobileMenu = ({
  classes,
  referralCode,
  navigationItems,
  onClick,
  chipContents,
  showReferralLink,
}: Props) => {
  const history = useHistory();
  const onSignOut = () => {
    firebase.auth().signOut();
    deleteAccessToken();
    history.push(PUBLIC_ROUTES.ROOT);
  };

  function isReferralLink(to: string) {
    return to === PRIVATE_ROUTES.ACCOUNT_REFERRAL.path;
  }

  return (
    <Section
      containerClass={classes.section}
      title={'My Account'}
      subtitle={<SectionActionButton onClick={onSignOut} label="Log Out" />}>
      <nav>
        <ul className={classes.navList}>
          {navigationItems.map((item: Navigation, index: number) => {
            if (!item.isEnabled()) return null;

            return (
              <li key={index}>
                <NavLink
                  to={{
                    pathname: `${item.to}`,
                  }}
                  onClick={onClick}
                  className={clsx(
                    classes.link,
                    isReferralLink(item.to) && classes.referralNavLink,
                  )}>
                  <span>
                    <span className={classes.header}>
                      <item.Icon className={classes.icon} />
                      <span className={classes.title}>{item.title}</span>
                      {item.hasChipContent && chipContents && (
                        <ReturnByDateChip
                          returnByDate={chipContents}
                          isMobile
                        />
                      )}
                    </span>

                    <Typography component="p" className={classes.subtitle}>
                      {item.subtitle}
                    </Typography>
                  </span>
                  <span className={classes.chevronIcon}>
                    <IconChevron />
                  </span>
                </NavLink>
              </li>
            );
          })}
        </ul>
        <MobileFeedbackContact />

        {showReferralLink && referralCode && (
          <ReferralLink referralCode={referralCode} />
        )}
      </nav>
    </Section>
  );
};

export default withStyles(styles)(MobileMenu);
