import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  title: string;
  okText?: string;
};

export const ConfirmationDialog: React.FC<Props> = ({
  open,
  onClose,
  onClick,
  title,
  children,
  okText = 'OK',
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          position: 'relative',
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '16px 24px 0',
        }}>
        <DialogTitle
          sx={{
            m: 0,
            p: 0,
            fontWeight: 'bold',
            flex: 1,
            textAlign: 'center',
          }}>
          {title}
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{justifyContent: 'center', p: '0 24px 24px'}}>
        <Button onClick={onClick} sx={{fontSize: '16px', fontWeight: '800'}}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
