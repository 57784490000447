import React from 'react';
import {Box, useTheme} from '@mui/material';
import {ReactComponent as LinkIcon} from '../../../../shared/assets/LinkIcon.svg';
import {ReactComponent as SuccessfulReferrals} from '../../../../shared/assets/successful-referrals-icon.svg';
import {ReactComponent as Rewards} from '../../../../shared/assets/rewards-icon.svg';
import ReferralCard from './components/ReferralCard';
import Section from 'customerPortal/components/Section';
import {ReferralHeader} from './components/ReferralHeader';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {useReferralEarnedRewards} from './ReferralScreen.service';
import {Customer, isInAppCustomer} from 'shared/types/customer';

interface Props {
  customer?: Customer;
}

export function ReferralScreen({customer}: Props) {
  const theme = useTheme();
  const {data: rewards, loading, error} = useReferralEarnedRewards();

  const inAppCustomer = isInAppCustomer(customer?.billingSource);

  return (
    <Section
      title="Referrals"
      loading={loading}
      error={error ? 'Error loading referral rewards' : ''}>
      <ReferralHeader isInAppCustomer={inAppCustomer} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          paddingBottom: '64px',
          [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
          },
        }}>
        <ReferralCard
          title="Referral link"
          description="This is your unique referral link. Share this link to begin earning rewards."
          icon={LinkIcon}
          referralCode={rewards?.referralCode}
        />
        <ReferralCard
          title="Successful referrals"
          description="When a customer makes their first payment to Ello, you get rewarded."
          metric={`${rewards?.successfulConversions || 0}`}
          icon={SuccessfulReferrals}
        />
        {!inAppCustomer && (
          <ReferralCard
            title="Total savings"
            description="This is how much you've saved by referring new customers."
            metric={`$${rewards?.totalSavings?.toFixed(2) || '0.00'}`}
            icon={Rewards}
          />
        )}
      </Box>
    </Section>
  );
}
