import React, {useRef, useState} from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {CopyComponentService} from './CopyComponent.service';
import useStyles from './CopyComponent.styles';
import CopiedText from './CopySheet.copied-text';
import CopyButton from './CopySheet.copy-button';

type Props = {
  link: string;
  displayLink?: string;
  onCopyClick?: (value: boolean) => void;
  style?: any;
};

const CopySheet = ({link, displayLink, onCopyClick, style}: Props) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const inputRef = useRef(null);
  const handleDoubleClick = () => {
    if (inputRef.current) {
      const input = inputRef.current as any;
      input.select();
    }
  };

  const copyToClipboard = async () => {
    const hasCopiedLink = await CopyComponentService.copyLinkToClipboard(link);
    onCopyClick && onCopyClick(hasCopiedLink);
    setCopied(hasCopiedLink);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <TextField
      style={{...style}}
      value={displayLink || link}
      inputRef={inputRef}
      className={classes.copySheet}
      onDoubleClick={handleDoubleClick}
      InputProps={{
        className: classes.copySheetInput,
        endAdornment: (
          <InputAdornment position="end">
            {copied ? (
              <CopiedText />
            ) : (
              <CopyButton copyToClipboard={copyToClipboard} />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CopySheet;
