import React from 'react';
import {Card, Box, CardContent, Stack, useTheme} from '@mui/material';
import {CardBody} from './CardBody';
import {ReferralMetric} from './ReferralMetric';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {gray100, white} from 'shared/styles/palette';
import CopyComponent from 'shared/components/common/CopyComponent/CopyComponent';
import AnalyticsService, {ShareMethod} from 'analytics/Analytics.service';
import {
  ReferralLinkService,
  shareBodyMessage,
} from 'customerPortal/components/ReferralLink/ReferralLink.service';
import {ReferralLinkService as SharedReferralLinkService} from 'shared/components/ReferralLink/ReferralLink.service';

type Props = {
  icon: React.ComponentType;
  metric?: string;
  title: string;
  description: string;
  referralCode?: string;
};

const ReferralCard: React.FC<Props> = ({
  icon: Icon,
  metric,
  title,
  description,
  referralCode,
}) => {
  const theme = useTheme();
  const {trackReferral} = SharedReferralLinkService.useTrackReferralLinkClick();
  const link = ReferralLinkService.referralLink(referralCode);
  const displayLink = link.replace('https://', '').replace('http://', '');

  const onCopyClick = () => {
    AnalyticsService.trackOnboardingFlowLinkClicked(
      ShareMethod.CopyToClipboard,
    );
    trackReferral(referralCode);
  };

  const onShare = () => {
    AnalyticsService.trackOnboardingFlowLinkClicked(ShareMethod.NativeShare);
    trackReferral(referralCode);
  };

  return (
    <Card
      sx={{
        padding: '24px',
        borderRadius: '16px',
        border: `1px solid ${gray100}`,
        backgroundColor: white,
        boxShadow: '4px 8px 20px 0px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
          paddingBottom: '32px',
          maxWidth: '256px',
        },
      }}>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          mb: '12px',
          [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
            mb: '20px',
          },
        }}>
        <Icon />
      </Box>

      <CardContent
        sx={{flexGrow: '1', padding: 0, '&:last-child': {paddingBottom: 0}}}>
        <Stack gap="16px" justifyContent="space-between" sx={{height: '100%'}}>
          <CardBody title={title} description={description} />
          {metric ? (
            <ReferralMetric metric={metric} />
          ) : (
            <CopyComponent
              link={link}
              displayLink={displayLink}
              copySheetStyle={{width: '100%'}}
              shareButtonText="Share Ello"
              dialogueText={shareBodyMessage}
              buttonType="secondary"
              buttonVariant="contained"
              onCopyClick={onCopyClick}
              onShare={onShare}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ReferralCard;
