import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {BORDER_RADIUS} from 'shared/styles/breakpoints';
import * as palette from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    navList: {
      padding: 0,
      margin: 0,
      listStyleType: 'none',
    },
    linkContainer: {
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    navigationLink: {
      backgroundColor: 'transparent',
      display: 'block',
      borderRadius: BORDER_RADIUS,
      textDecoration: 'none',
      fontWeight: 700,
      color: palette.black,
      outline: 'none',
      '&:hover, &:focus': {
        backgroundColor: palette.gray,
      },
    },
    referralNavLink: {
      color: palette.turquoise900,
    },
    activeLink: {
      backgroundColor: palette.turquoise100,
      cursor: 'default',
      '&:hover, &:focus': {backgroundColor: palette.turquoise100},
      paddingRight: theme.spacing(1),
    },
    referralActiveLink: {
      backgroundColor: palette.turquoise50,
      '&:hover, &:focus': {backgroundColor: palette.turquoise50},
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.125rem',
    },
  });

export default styles;
