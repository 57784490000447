import React from 'react';
import {Typography, Link, useTheme} from '@mui/material';
import {gray900, gray700} from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const faqLink = 'https://www.ello.com/#faqs';

type Props = {
  isInAppCustomer: boolean;
};

export const ReferralHeader: React.FC<Props> = ({isInAppCustomer}) => {
  const theme = useTheme();

  const title = isInAppCustomer
    ? 'Share Ello with friends and family - they will get a free first month and you get a free Ello plushie '
    : 'Share Ello with friends and family and you both get one month free! ';

  return (
    <Typography
      sx={{
        textAlign: 'center',
        lineHeight: '1.6',
        letterSpacing: '-0.048px',
        color: gray900,
        pt: '24px',
        pb: '32px',
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
          fontSize: '20px',
          py: '32px',
          color: gray700,
          textAlign: 'left',
        },
      }}>
      {title}
      <Link
        sx={{
          fontWeight: '700',
        }}
        href={faqLink}
        rel="noopener"
        target="_blank">
        Learn more
      </Link>
    </Typography>
  );
};
