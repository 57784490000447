export function cleanPhoneInput(phone: string) {
  return phone.replace(/\D/g, '');
}

export function isValidPhoneNumber(phone: string, countryCode?: string) {
  if (countryCode === 'KE') {
    return /^\d{9}$/.test(cleanPhoneInput(phone));
  }

  return /^\d{10}$/.test(cleanPhoneInput(phone));
}
