import React from 'react';
import {ConfirmationDialog} from './ConfirmationDialog';
import {Typography} from '@mui/material';
import {gray900} from 'shared/styles/palette';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const ReceiverExistsDialog: React.FC<Props> = ({open, onClose}) => {
  return (
    <ConfirmationDialog
      open={open}
      title="All set!"
      onClose={onClose}
      onClick={onClose}>
      <Typography variant="body1" textAlign="center" mb="10px" color={gray900}>
        Looks like you already have an Ello account! Our team will apply the
        gift card to your account for three months of free access to the app and
        Ello’s coaching for the physical books in the box.
      </Typography>
      <Typography variant="body1" textAlign="center" color={gray900}>
        We’ll email you when everything is all set.
      </Typography>
    </ConfirmationDialog>
  );
};
