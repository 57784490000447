import React from 'react';
import {ConfirmationDialog} from './ConfirmationDialog';
import {Typography} from '@mui/material';
import {gray900} from 'shared/styles/palette';

type Props = {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
};

export const CustomerExistsDialog: React.FC<Props> = ({
  open,
  onClose,
  onClick,
}) => {
  return (
    <ConfirmationDialog
      open={open}
      title=""
      okText={'Log in Now →'}
      onClose={onClose}
      onClick={onClick}>
      <Typography
        variant="body1"
        textAlign="center"
        mb="10px"
        color={gray900}
        fontSize="20px">
        Looks like you already have an Ello account! Log in to renew your
        subscription.
      </Typography>
    </ConfirmationDialog>
  );
};
