import {useQuery} from '@apollo/client';
import {EarnedReferralRewards} from './ReferralScreen.types';
import {REFERRAL_EARNED_REWARDS} from './ReferralScreen.query';

export const useReferralEarnedRewards = () => {
  const {loading, data, error} = useQuery<{
    referralEarnedRewards: EarnedReferralRewards;
  }>(REFERRAL_EARNED_REWARDS);

  const rewards = data?.referralEarnedRewards;

  return {
    data: rewards,
    loading,
    error,
  };
};
