import gql from 'graphql-tag';

export const VALIDATE_ONBOARDING_DATA = gql`
  mutation ValidateOnboardingData(
    $email: String!
    $name: String!
    $phoneNumber: String!
    $isInternationalCustomer: Boolean
  ) {
    validateOnboardingData(
      email: $email
      name: $name
      phoneNumber: $phoneNumber
      isInternationalCustomer: $isInternationalCustomer
    )
  }
`;
