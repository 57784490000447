import React from 'react';
import {Typography} from '@mui/material';
import {blue500} from 'shared/styles/palette';

type Props = {
  metric: string;
};

export const ReferralMetric: React.FC<Props> = ({metric}) => {
  return (
    <Typography
      variant="h4"
      sx={{
        fontSize: '44px',
        fontWeight: '800',
        lineHeight: '1.3',
        letterSpacing: '-0.352px',
        color: blue500,
        textAlign: 'center',
      }}>
      {metric}
    </Typography>
  );
};
