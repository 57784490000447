import React from 'react';
import clsx from 'clsx';
import Button, {ButtonProps} from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {ReactComponent as ShareIcon} from '../../../assets/share.svg';
import {CopyComponentService} from './CopyComponent.service';
import useStyles from './CopyComponent.styles';
type Props = {
  link: string;
  buttonType: 'primary' | 'secondary';
  buttonText: string;
  buttonVariant?: ButtonProps['variant'];
  dialogueText?: string;
  onShare?: (value: boolean) => void;
};

const ShareSheet = ({
  link,
  buttonText,
  buttonType,
  dialogueText,
  onShare,
  buttonVariant = 'outlined',
}: Props) => {
  const isPrimary = buttonType === 'primary';
  const classes = useStyles({isPrimary});
  const shareLink = async () => {
    const copied = await CopyComponentService.copyLinkAndShare(
      link,
      dialogueText,
    );
    onShare && onShare(copied);
  };

  const isContained = buttonVariant === 'contained';

  return (
    <Button
      variant={buttonVariant}
      className={clsx(
        classes.shareButton,
        isContained && classes.shareButtonContained,
      )}
      onClick={shareLink}>
      <ShareIcon
        className={clsx(
          classes.shareIcon,
          isContained && classes.shareIconContained,
        )}
      />
      <Typography
        className={clsx(
          classes.shareButtonText,
          isContained && classes.shareButtonTextContained,
        )}>
        {buttonText}
      </Typography>
    </Button>
  );
};

export default ShareSheet;
