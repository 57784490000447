import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {WithStyles} from '@mui/styles';
import clsx from 'clsx';
import styles from './DesktopMenu.styles';
import {Navigation} from 'customerPortal/constants/navigationItems';
import Card from 'customerPortal/components/Card/Card';
import ReturnByDateChip from 'customerPortal/components/ReturnByDateChip/ReturnByDateChip';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';

interface Props extends WithStyles<typeof styles> {
  navigationItems: Navigation[];
  containerClass?: string;
  chipContents?: string;
}

const DesktopMenu = ({
  classes,
  containerClass,
  navigationItems,
  chipContents,
}: Props) => {
  function isReferralLink(to: string) {
    return to === PRIVATE_ROUTES.ACCOUNT_REFERRAL.path;
  }

  return (
    <Card className={containerClass}>
      <ul className={classes.navList}>
        {navigationItems.map((item: Navigation, index: number) => {
          if (!item.isEnabled()) return null;

          return (
            <li key={index} className={classes.linkContainer}>
              <NavLink
                to={{
                  pathname: `${item.to}`,
                }}
                className={clsx(
                  classes.navigationLink,
                  isReferralLink(item.to) && classes.referralNavLink,
                )}
                activeClassName={clsx(
                  classes.activeLink,
                  isReferralLink(item.to) && classes.referralActiveLink,
                )}>
                <span className={classes.title}>
                  <item.Icon />
                  <span>{item.title}</span>
                  {item.hasChipContent && chipContents && (
                    <ReturnByDateChip returnByDate={chipContents} />
                  )}
                </span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </Card>
  );
};

export default withStyles(styles)(DesktopMenu);
