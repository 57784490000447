import gql from 'graphql-tag';

export const REFERRAL_EARNED_REWARDS = gql`
  query ReferralEarnedRewards {
    referralEarnedRewards {
      referralCode
      totalSavings
      successfulConversions
    }
  }
`;
