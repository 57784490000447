import createStyles from '@mui/styles/createStyles';
import {makeStyles} from '@mui/styles';
import * as palette from '../../../styles/palette';

export default makeStyles(theme => {
  return createStyles({
    shareButton: (props: {isPrimary?: boolean} = {}) => ({
      width: '100%',
      paddingTop: '14px',
      paddingBottom: '14px',
      borderRadius: '50px',
      border: `1px solid ${
        props.isPrimary ? palette.turquoise900 : palette.blue500
      }`,
      backgroundColor: palette.white,
      '&:hover, &:active': {
        backgroundColor: props.isPrimary
          ? 'rgba(50, 184, 184, 0.07)'
          : 'rgba(51, 92, 110, 0.1)',
        border: `1px solid ${
          props.isPrimary ? palette.turquoise900 : palette.blue500
        }`,
      },
    }),
    shareButtonContained: {
      '&.MuiButton-contained': {
        border: 'none',
        backgroundColor: palette.turquoise900,
        '&:hover, &:active': {
          backgroundColor: palette.turquoise900,
          border: 'none',
        },
      },
    },
    shareIcon: (props: {isPrimary?: boolean} = {}) => ({
      marginRight: '12px',
      color: props.isPrimary ? palette.turquoise900 : palette.blue500,
    }),
    shareIconContained: () => ({
      color: palette.white,
    }),
    shareButtonText: (props: {isPrimary?: boolean} = {}) => ({
      fontSize: '16px',
      fontWeight: 800,
      color: props.isPrimary ? palette.turquoise900 : palette.blue500,
    }),
    shareButtonTextContained: () => ({
      color: palette.white,
    }),
    copySheet: {
      backgroundColor: palette.white,
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          border: `1px solid ${palette.turquoise900}`,
        },
      },
      '& fieldset': {
        border: `1px solid ${palette.gray300}`,
        borderRadius: '8px',
        padding: '3px 16px',
      },
    },
    copySheetInput: {
      color: palette.gray500,
      fontSize: '16px',
      opacity: 1,
      lineHeight: '24px',
      fontWeight: 400,
      caretColor: 'transparent',
      cursor: 'text',
    },
    copiedContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    copiedIcon: {
      marginRight: '12px',
      color: palette.teal500,
    },
    copiedText: {
      color: palette.teal500,
      textTransform: 'uppercase',
      fontWeight: 800,
      fontSize: '16px',
    },
    copySheetButtonText: {
      textTransform: 'uppercase',
      fontWeight: 800,
      letterSpacing: '-0.048px',
      fontSize: '16px',
      color: palette.turquoise900,
    },
    copyIcon: {
      marginRight: '12px',
    },
  });
});
