import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {gray700, gray900} from 'shared/styles/palette';

type Props = {
  title: string;
  description: string;
};

export const CardBody: React.FC<Props> = ({title, description}) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h5"
        fontWeight="700"
        sx={{
          fontSize: '22px',
          fontWeight: 700,
          lineHeight: '1.6',
          letterSpacing: '-0.066px',
          textAlign: 'center',
          color: gray900,
          [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
            mb: '20px',
            lineHeight: '1.35',
            letterSpacing: '-0.16px',
          },
        }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: '16px',
          lineHeight: '1.7',
          letterSpacing: '-0.048px',
          textAlign: 'center',
          color: gray700,
          [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
            fontSize: '14px',
            lineHeight: '1.6',
            letterSpacing: '-0.112px',
          },
        }}>
        {description}
      </Typography>
    </Box>
  );
};
