import React from 'react';
import {TABLET_BREAKPOINT} from '../../../styles/breakpoints';
import {useWindowSize} from '../../../utils/layoutHooks';
import {CopyComponentService} from './CopyComponent.service';
import CopySheet from './CopySheet';
import ShareSheet from './ShareSheet';
import {ButtonProps} from '@mui/material';

type Props = {
  link: string;
  displayLink?: string;
  dialogueText?: string;
  onShare?: (value: boolean) => void;
  onCopyClick?: (value: boolean) => void;
  copySheetStyle?: any;
  shareButtonText: string;
  buttonType: 'primary' | 'secondary';
  buttonVariant?: ButtonProps['variant'];
};

const CopyComponent = ({
  link,
  displayLink,
  buttonType,
  dialogueText,
  onShare,
  onCopyClick,
  copySheetStyle,
  shareButtonText,
  buttonVariant,
}: Props) => {
  const [windowWidth] = useWindowSize();
  const isMobile = windowWidth < TABLET_BREAKPOINT;
  const canShareLink = CopyComponentService.canShareLink();
  const showShareSheet = canShareLink && isMobile;
  return showShareSheet ? (
    <ShareSheet
      link={link}
      buttonType={buttonType}
      dialogueText={dialogueText}
      onShare={onShare}
      buttonText={shareButtonText}
      buttonVariant={buttonVariant}
    />
  ) : (
    <CopySheet
      link={link}
      displayLink={displayLink}
      onCopyClick={onCopyClick}
      style={copySheetStyle}
    />
  );
};

export default CopyComponent;
